const env = process.env.VUE_APP_ENV;
const isProduction = env === 'production' ? true : false;

export default {
    URL: isProduction ? 'https://api.meversedex.io' : 'https://api-test.meversedex.io',
    DECIMAL18: 1e18,
    METAMASK: 'metamask',
    MEVERSE_DAY_PER_BLOCK: 172800,
    MEVERSE_NUMBER: 0,
    CHAIN_NAME: {
        MEVERSE: 'meverse',
    },
    TOKEN: {
        MPL: isProduction ? '0xbd95e72f28b5ac5ee4d99b6a8bcb2d03f25247e3' : '0xc1286864c2391139bb0012e1614d7d14c306f455',
    },
    CONVERTER_ADDRESS: isProduction ? '0xc3990500bc0608c7080f161ebc384d76ffd4cf56' : '0x9ce28e6e122b2779ae92cb744be0ea0aa3970c31',
    SYMBOL: {
        MPL: require(`../assets/media/Symbol/MPL.svg`),
    },
}