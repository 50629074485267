const dots = '...';
export const concatAddress = (address) => {
  let head = address.slice(0, 4);
  let foot = address.slice(address.length-4, address.length)
  return head.concat(dots).concat(foot);
}

// export const concatAddress = (address) => {
//   let head = address.slice(0, 4);
//   let foot = address.slice(38, 42)
//   return head.concat(dots).concat(foot);
// }

export const concatHash = (hash) => {
  let head = hash.slice(0, 20);
  let foot = hash.slice(40, 66);
  return head.concat(dots).concat(foot);
}

export const concatHashFromHistory = (hash) => {
  let head = hash.slice(0, 5);
  let foot = hash.slice(61, 66);
  return head.concat(dots).concat(foot);
}