<template>
  <div class="view">
    <div class="view__inner">
      <section class="section01">
        <div class="section01__inner desktop" :class="{ 'countView': countDown > 0 }">
          <div class="inner-info">
            <p class="section01__inner--title">Pocket Battles: NFT War<br />Season 6</p>
            <p class="section01__inner--sub">Stay tuned for the new update!</p>
            <div class="section01__inner--btn-wrap">
              <v-btn href="https://medium.com/@pocketbattlesnftwar/pocket-championship-season-6-announcement-7a154246512e" target="_black" class="season-btn">Season 6</v-btn>
              <v-btn @click="openDownloadModal" class="download-btn">Download</v-btn>
            </div>
          </div>
        </div>
      </section>

      <section class="section-video">
        <div class="section-video__inner">
          <div class="section-video__inner--item">
            <img class="thumbnail" src="@/assets/media/Content/Section-video_thumbnail.png" alt="포켓배틀스 NFT video / Pocket Battles: NFT War Trailer video">
            <button @click="openVideoModal" class="play-btn"></button>
          </div>
        </div>
      </section>

      <section id="nft-heroes" class="content section02">
        <div class="content__title">
          <p class="content__title--first">Pocket Battles: NFT War<br />NFT Heroes</p>
          <p class="content__title--sub">NFT Heroes are stronger and more skilful than normal heroes.<br class="break" />Total of 22 NFT Heroes are waiting for you! Get NFT Heroes to get stronger!</p>
          <v-btn target="_blank" href="https://www.meversedex.io/marketplace/dashboard?u=b3917cc6-47c8-4eab-9f1b-44c6eac608a9" class="content__title--btn">Get Heroes NFT</v-btn>
        </div>

        <div class="section02__inner">
          <div v-for="index in 12" :key="index" class="section02__inner--card">
            <img :class="[ `Hero${[index]}` ]" :src="require(`../assets/media/Content/Heroes/Hero${index}.png`)" />
          </div>
        </div>
      </section>

      <section id="features" class="section03">
        <div class="section03__inner">
          <div class="swiper">
            <div class="phone-swiper-wrap">
              <div class="phone-swiper">
                <div class="swiper-wrapper">
                  <div v-for="(item, index) in sildeItem" :key="index" class="swiper-slide">
                    <img :src="item.image" />
                  </div>
                </div>
              </div>
            </div>

            <div class="desc-swiper-wrap">
              <div class="desc-swiper">
                <div class="swiper-wrapper">
                  <div v-for="(item, index) in sildeItem" :key="index" class="swiper-slide">
                    <div class="swiper-info">
                      <p class="swiper-info__title">{{ item.title }}</p>
                      <span class="swiper-info__desc">{{ item.desc }}</span>
                    </div>
                  </div>
                  <div class="swiper-pagination inner-pagination"></div>
                </div>
              </div>
            </div>

            <div class="swiper-pagination wrap-pagination"></div>
          </div>
        </div>
      </section>

      <section id="get-token" class="content section04">
        <div class="section04__inner">

          <div class="content__title">
            <p class="content__title--first">Pocket Stone &amp; Token</p>
            <div class="content__title--sub">
              <p>· Pocket Stone(PKS) is a in-game currency that can be obtained by game play.</p>
              <p>· Pocket Stone(PKS) and MEVerse Play(MPL) can be exchanged. <br class="break" />*initial exchange ratio is 1:1, but can be changed upon the reserves.</p>
              <p>· MEVerse Play(MPL) can be swapped with other tokens on MEVerse DEX.</p>
            </div>
            <v-btn target="_blank" href="https://www.meversedex.io/swap?f=USDC&t=POCKET" class="content__title--btn">Get MPL in MEVerse DEX</v-btn>
          </div>

          <div class="section04__inner--token">
            <p>MEVerse DEX</p>
            <ul>
              <li>
                <img src="../assets/media/Symbol/PKS.svg" alt="PKS image">
                <p>PKS</p>
              </li>
              <li>
                <img src="../assets/media/Symbol/MPL.svg" alt="MPL image">
                <p>MPL</p>
              </li>
              <li>
                <img src="../assets/media/Symbol/USDC.svg" alt="USDC image">
                <p>USDC</p>
              </li>
            </ul>
          </div>
        </div>
      </section>

      <section id="news" class="content section05">
        <div class="section05__inner">
          <div class="content__title">
            <p class="content__title--first">Latest News</p>
          </div>
          <div class="section05__inner--list">
            <a v-show="index <= 5" :href="item.link" target="_blank" v-for="(item, index) in list" :key="index" class="news-item">
              <div class="news-item__thumbnail">
                <v-img :src="item.image"></v-img>
              </div>
              <div class="news-item__info">
                <p>{{ item.title }}</p>
                <span>{{ item.date }}</span>
              </div>
            </a>
          </div>
        </div>
      </section>
    </div>

    <div v-show="modal_video" class="video-modal">
      <div class="video-modal__inner">
        <div class="video-modal__inner--video">
          <v-btn class="close-btn" @click="closeVideoModal"></v-btn>
          <iframe src="https://www.youtube.com/embed/eJL4E3UduSM"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen></iframe>
        </div>
      </div>
    </div>

    <v-dialog v-model="modal_download">
      <div class="Modal Download">
        <div class="Modal__header">
          <p class="Modal__header--title">Download</p>
          <v-btn class="Modal__header--close" @click="closeDownloadModal"></v-btn>
        </div>
        <div class="Modal__body">
          <div class="Modal__body--list">
            <v-btn target="_blank" :href="item.link"
              v-for="(item, index) in downloadItem"
              :key="index"
              :class="[ item.class ]">
              {{ item.title }}
            </v-btn>
          </div>
        </div>
      </div>
    </v-dialog>

  </div>
</template>

<script>
import Swiper from 'swiper/bundle';
import 'swiper/swiper-bundle.css';
export default {
  name: 'HomeView',
  data() {
    return {
      modal_video: false,
      modal_download: false,
      list: [],
      countDown: 0,
      downloadItem: [
        {
          title: 'Google Play',
          class: 'GooglePlay',
          link: 'https://play.google.com/store/apps/details?id=com.m2o.pocket.battles',
        },
        {
          title: 'App Store',
          class: 'AppStore',
          link: 'https://apps.apple.com/us/app/pocket-battles-nft-war/id1628708730',
        },
        {
          title: 'Download APK',
          class: 'Download',
          link: 'https://cdndown.fhcasino.com/pocketbattles/apk/PocketBattles_NFTWar.apk ',
        },
      ],
      sildeItem: [
        {
          title: 'Stunning Graphic',
          desc: "It's fun to play and watch various heroes and soldiers with stunning and eye-catching graphics made by UNITY engine.",
          image: require(`../assets/media/Content/Slide/slide_01.png`)
        },
        {
          title: 'The Joy of Strategic Battle',
          desc: "The battle isn't simply about having higher battle power. The player can build an optimal strategic squad and formation to fight the enemy.",
          image: require(`../assets/media/Content/Slide/slide_02.png`)
        },
        {
          title: 'The Joy of Upgrade',
          desc: 'The player can collect soldiers and heroes and upgrade them to strengthen his squad to fight the enemy.',
          image: require(`../assets/media/Content/Slide/slide_03.png`)
        },
        {
          title: 'The Joy of Competition',
          desc: 'The player can enjoy various PvP contents such as Arena and Pocket Championship to compete with other user and earn more rewards.',
          image: require(`../assets/media/Content/Slide/slide_04.png`)
        },
        {
          title: 'The Joy of P2E',
          desc: 'The player can not only enjoy playing the game but can also earn Pocket Stone by Daily Quest, Arena, and Pocket Championship.',
          image: require(`../assets/media/Content/Slide/slide_05.png`)
        },
      ]
    }
  },
  mounted() {
    this.render();
    this.getData();
  },
  methods: {
    async getData() {
      let datas = [];

      const name = "pocketbattlesnftwar";
      const RSSUrl = `https://medium.com/feed/@${name}`;
      const RSSConverter = `https://api.rss2json.com/v1/api.json?rss_url=${RSSUrl}`;

      const response = await fetch(RSSConverter);
      const data = await response.json();

      for(let item of data.items) {
        datas.push({
          "title": item.title,
          "link": item.link,
          "image": item.thumbnail,
          "description": item.description,
          "date": item.pubDate })
      }

      this.list = datas;
    },
    // getData() {
    //   return new Promise((resolve, reject) => {
    //   const url = `https://apihomepage.meverse.sg/news/medium?project=PocketBattles`
    //     fetch(url, {
    //         method: 'GET',
    //     })
    //     .then(res => res.json())
    //     .then(res => {
    //         this.list = res;
    //         resolve(this.list);
    //         console.log(res);
    //     })
    //     .catch(e => {
    //         reject('err: ',e)
    //     });
    //   });
    // },
    render() {
      const phoneSwiper = new Swiper(".swiper .phone-swiper", {
        loop: true,
        pagination: {
          el: ".wrap-pagination",
          clickable : true,
        },
      });

      const descSwiper = new Swiper(".swiper .desc-swiper", {
        autoplay: true,
        speed: 500,
        loop: true,
        observer: true,
        observeParents: true,
	      effect : 'fade',
        allowTouchMove: false,
        pagination: {
          el: ".inner-pagination",
          clickable : true,
        },
      });

      phoneSwiper.controller.control = descSwiper;
      descSwiper.controller.control = phoneSwiper;
    },
    openVideoModal() {
      this.modal_video = true;
    },
    closeVideoModal() {
      this.modal_video = false;
    },
    openDownloadModal() {
      this.modal_download = true;
    },
    closeDownloadModal() {
      this.modal_download = false;
    }
  }
}
</script>

<style>

</style>