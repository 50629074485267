import tokenABI from '@/assets/abi/tokenABI.json';
import requestABI from '@/assets/abi/requestABI.json';
import define from '@/define';

const MEVERSE = 0;
const env = process.env.VUE_APP_ENV;

export default {
  [MEVERSE]: {
    token: {
      MPL: define.TOKEN.MPL,
      abi: tokenABI,
    },
    converter: {
      address: define.CONVERTER_ADDRESS,
      abi: requestABI,
    },
  },
}