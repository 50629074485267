<template>
  <div>
    <footer class="footer">
      <div class="footer__inner">
        <div class="footer-logo"></div>
        <div class="footer__inner--info">
          <p>Official Email :  pocketbattlesnftwar@gmail.com</p>
          <p>COPYRIGHT © ME2ON Co.,Ltd. All rights Reserved.</p>
        </div>
        <div class="sns-wrap">
          <a target="_blank" href="https://www.facebook.com/PBNFTWar" class="facebook"></a>
          <a target="_blank" href="https://medium.com/@pocketbattlesnftwar" class="medium"></a>
          <a target="_blank" href="https://discord.com/invite/meverseofficialchannel" class="discord"></a>
          <a target="_blank" href="https://twitter.com/PBNFTWar" class="twitter"></a>
          <a target="_blank" href="https://meversedex.gitbook.io/pocketbattlesnftwar/" class="gitbook"></a>
        </div>
      </div>
    </footer>
    <div class="fixed-sns">
      <div @click="goExchange" class="fixed-toExchange">
        <img class="stone-img" src="@/assets/media/Symbol/PKS_nonBg.svg" alt="PKS symbol image">
        <div class="fixed-toExchange__inner">
          <p>Exchange</p>
        </div>
      </div>

      <img class="angel-img" src="@/assets/media/Icon/Angel.png" alt="fixed sns angel icon">
      <div class="sns-wrap">
        <a target="_blank" href="https://www.facebook.com/PBNFTWar" class="Facebook"></a>
        <a target="_blank" href="https://discord.com/invite/meverseofficialchannel" class="Discord"></a>
        <a target="_blank" href="https://twitter.com/PBNFTWar" class="Twitter"></a>
        <a target="_blank" href="https://medium.com/@pocketbattlesnftwar" class="Medium"></a>
        <a target="_blank" href="https://meversedex.gitbook.io/pocketbattlesnftwar/" class="Gitbook"></a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppFooterView',
  data() {
    return {

    }
  },
  methods: {
    goExchange() {
      if (this.$route.name == 'exchange') return;
      this.$router.push('/exchange');
    }
  }
}
</script>

<style>

</style>