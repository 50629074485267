<template>
<div v-if="modal_info && $route.name === 'exchange'" class="info-bar-wrap">
  <div class="info-bar">
    <div class="info-bar__inner">
      <img src="@/assets/media/Icon/Metamask.svg" alt="metamask 메타마스크 icon 아이콘">
      <p>Please login with Metamask Browser.</p>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: 'infoBarView',
  data() {
    return {
      modal_info: false
    }
  },
  mounted() {
    this.showInfoMetamask();
  },
  methods: {
    showInfoMetamask() {
      let isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
      if (!window.ethereum && isMobile) {
        this.modal_info = true;
        return;
      }
    },
  }
}
</script>

<style>

</style>