<template>
  <div class="converter">
    <div class="converter__inner">
      <div class="content-box">
        <div class="content-box__header" :class="{ 'info-haeder': !walletAddress }">
          <div v-if="walletAddress" class="content-box__header--info">
            <div class="info-box">
              <p class="info-box__title">Wallet Address</p>
              <div class="info-box__inner wallet">
                <p>{{ concatAddress(walletAddress) }}</p>
                <v-btn class="disconnect" @click="logout"></v-btn>
              </div>
            </div>
            <div class="info-box">
              <p class="info-box__title">MPL Balance</p>
              <div v-if="balance" class="info-box__inner balance">
                <img src="@/assets/media/Symbol/MPL.svg" alt="MPL symbol image">
                <div class="info-box__inner--text">
                  <p>{{ balance ? toNumberFormat(balance) : 0 }}</p>
                  <span>MPL</span>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="content-box__header--connect">
            <p>Connect wallet to see your MPL balance.</p>
          </div>
        </div>
        <div class="content-box__body">
          <div class="content-box__body--receive">
            <div class="info-box input-wrap">
              <p class="info-box__desc">To receive</p>
              <v-text-field
                v-model="requestValue"
                :label="'0'"
                @keyup="toAmountFormat"
                solo
                type="number"
              ></v-text-field>
              <p class="info-box__desc">PKS</p>
            </div>
            <div class="info-box info-wrap">
              <p class="info-box__desc">You need</p>
              <div class="ratio">
                <div class="ratio__inner">
                  <p>{{ payMpl != '....' && payMpl != '' ? toNumberFormat(payMpl) : '0'  }}</p>
                </div>
                <span>1 PKS = {{ perPrice }} MPL</span>
              </div>
              <p class="info-box__desc">MPL</p>
            </div>
          </div>
          <div class="content-box__body--info">
            <div class="change-info MPL">
              <img src="@/assets/media/Symbol/MPL.svg" alt="MPL symbol image">
              <div class="change-info__text">
                <p>{{ payMpl != '....' && payMpl != '' ? toNumberFormat(payMpl) : '0'  }}</p>
                <span>MPL</span>
              </div>
            </div>
            <img src="@/assets/media/Icon/Next.svg" alt="next icon image">
            <div class="change-info PKS">
              <img src="@/assets/media/Symbol/PKS_nonBg.png" alt="PKS symbol image">
              <div class="change-info__text">
                <p>{{ requestValue ? toNumberFormat(requestValue) : '0' }}</p>
                <span>PKS</span>
              </div>
            </div>
          </div>
        </div>
        <div class="content-box__footer">
          <v-btn v-if="!isAllowChain" @click="changeChain" class="walletWrong">Wrong Network</v-btn>
          <div v-else-if="walletAddress">
            <v-btn @click="approve" v-if="allowance === '0' && walletAddress" :loading="loading_approve">Approve</v-btn>
            <v-btn v-else :disabled="!requestValue || bipayMpl > biBalance || requestValue < 300" @click="openConfirm">Request stone</v-btn>
          </div>
          <v-btn v-else @click="login">Connect Wallet</v-btn>
          <div class="content-box__footer--info">
            <p>· Exchange fee 0.1% included.</p>
            <p>· The sample exchange rate is for reference only. The actual rate will vary depending on the date of the exchange.</p>
            <p class="PrimaryColor">· The minimum exchange quantity is 300 PKS.</p>
          </div>
        </div>
      </div>
    </div>

    <v-dialog v-model="modal_confirm">
      <div class="Modal Confirm">
        <v-btn @click="closeConfirm" class="close-btn"></v-btn>
        <div class="Modal__header">
          <p>Request Stone</p>
        </div>
        <div class="Modal__body">
          <div class="Modal__body--change">
            <div class="change-info MPL">
              <img src="@/assets/media/Symbol/MPL.svg" alt="MPL symbol image">
              <div class="change-info__text">
                <p>{{ payMpl != '....' && payMpl != '' ? toNumberFormat(payMpl) : '0'  }}</p>
                <span>MPL</span>
              </div>
            </div>
            <img src="@/assets/media/Icon/Next.svg" alt="next icon image">
            <div class="change-info PKS">
              <img src="@/assets/media/Symbol/PKS_nonBg.svg" alt="PKS symbol image">
              <div class="change-info__text">
                <p>{{ requestValue ? toNumberFormat(requestValue) : '0' }}</p>
                <span>PKS</span>
              </div>
            </div>
          </div>
          <div class="Modal__body--checkBox">
            <input v-model="checked" type="checkbox" id="info1" class="checkbox-custom">
            <label for="info1">
              <span>I confirm that this wallet address is linked with my account in Pocket Battles: NFT War</span>
            </label>
          </div>
        </div>
        <div class="Modal__footer">
          <v-btn :disabled="!checked" @click="requestStone" :loading="loading_converter">Confirm</v-btn>
        </div>
      </div>
    </v-dialog>

    <v-dialog v-model="modal_final">
      <div class="Modal Confirm">
        <div class="Modal__header">
          <p>Transaction Confirmed!</p>
        </div>
        <div class="Modal__body">
          <div class="Modal__body--info">
            <img src="@/assets/media/Icon/Confirm.svg" alt="Confirm Icon 컨펌 아이콘 확인">
            <p>Your <span>{{ payMpl != '....' && payMpl != '' ? toNumberFormat(payMpl) : '0'  }}</span> MPL has been swapped for <span>{{ requestValue ? toNumberFormat(requestValue) : '0' }}</span> PKS. Please check your PKS balance in game.</p>
          </div>
        </div>
        <div class="Modal__footer">
          <v-btn @click="closeFinal">Confirm</v-btn>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import define from '@/define';
import config from '@/config';
import web3Utils from 'web3-utils';
import { manager } from '@/libs/web3Manager';
import { toNumberFormat } from '@/utils/toNumberFormat';
import { concatAddress } from '@/utils/concat';
import { addChainPromise } from '@/utils/chainToConnect';
import { connectToMetamask } from '@/utils/metamaskLogin';
export default {
  name: 'ExchangeView',
  data() {
    return {
      requestValue: '',
      balance: "0",
      payMpl: "0",
      updateCount : 0,
      allowance: '',
      symbol: define.SYMBOL,
      loading_approve: false,
      loading_converter: false,
      modal_confirm: false,
      modal_final: false,
      checked: false,
    }
  },
  computed: {
    bipayMpl() {
      if (this.payMpl == "....") {
        return BigInt(0)
      }
      return BigInt(web3Utils.toWei(this.payMpl, 'ether'))
    },
    biBalance() {
      return BigInt(web3Utils.toWei(this.balance, 'ether'))
    },
    walletAddress() {
      return this.$store.state.user.userInfo.walletAddress;
    },
    chainId() {
      return this.$store.state.chain.chain.id;
    },
    isAllowChain() {
      if (window.ethereum && window.ethereum?.chainId) {
        const chainId = window.ethereum.chainId;
        if (config.allowChain.includes(chainId)) {
          return true;
        }
      }
      return this.$store.getters.isAllowChain;
    },
    perPrice() {
      if (!this.requestValue || !this.payMpl) {
        return 0;
      }
      return parseFloat( this.payMpl / this.requestValue).toFixed(3);
    },
  },
  watch: {
    walletAddress() {
      if (this.walletAddress) {
        this.init();
      } else {
        this.refresh();
      }
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    refresh() {
      this.checked = false;
      this.requestValue = '';
      this.balance = '0';
      this.payMpl = '0';
      this.updateCount = 0;
      this.allowance = '';
    },
    toNumberFormat,
    concatAddress,
    changeChain() {
      if (!window.ethereum) {
        let isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
        if (isMobile) {
          window.location.href = 'https://metamask.app.link/dapp/pocketbattlesnftwar.com';
          return;
        }
        window.open('https://metamask.io/download', 'metamask');
        return;
      }

      try {
        addChainPromise(define.METAMASK, define.MEVERSE_NUMBER)
        .then(() => {
          connectToMetamask();
        })
      } catch(e) {
        console.log('e: ', e);
      }
    },
    async login() {
      await connectToMetamask();
    },
    async logout() {
      this.$store.dispatch('logout');
      this.drop = false;
      this.modal_wallet = false;
    },
    toAmountFormat() {
      if (!this.requestValue || this.requestValue <= 0) {
        this.payMpl = '0';
        this.requestValue = '';
        return;
      }
      if (Number(this.balance) < this.requestValue) {
        this.requestValue = Math.trunc(this.balance);
      }
      const value = Math.trunc(this.requestValue);
      this.requestValue = Math.trunc(value);
      this.getCallRatio();
    },
    init() {
      this.getAllowence();
      this.getBalanceOf();
    },
    approve() {
      this.loading_approve = true;
      const converterAddress = config.contract[define.MEVERSE_NUMBER].converter.address;
      const token = 'MPL';

      manager.approve(token, converterAddress)
      .then(res => {
        try {
          setTimeout(async () => {
            await this.getAllowence();
            this.loading_approve = false;
          }, 2000);
        } catch(e) {
          console.log('e: ', e);
        }
        // console.log('approve', res);
      })
      .catch(e => {
        this.loading_approve = false;
        console.log('approve', e);
      })
    },
    openConfirm() {
      this.modal_confirm = true;
    },
    openFinal() {
      this.modal_final = true;
    },
    closeConfirm() {
      this.modal_confirm = false;
      this.checked = false;
    },
    async closeFinal() {
      await this.refresh();
      await this.init();
      this.modal_final = false;
    },
    getAllowence() {
      if (!this.walletAddress) return;
      const converterAddress = config.contract[define.MEVERSE_NUMBER].converter.address;
      const token = 'MPL';
      manager.getAllowance(token, this.walletAddress, converterAddress)
      .then(res => {
        this.allowance = res;
        // console.log('allowance', res);
      })
      .catch(e => {
        console.log('e: ', e);
      })
    },
    getBalanceOf() {
      const userAddress = this.$store.state.user.userInfo.walletAddress;
      const token = "MPL";
      if (userAddress) {
        manager.getBalanceOf(token, userAddress)
        .then(res => {
          // console.log("res", res)
          this.balance = web3Utils.fromWei(res, 'ether');
        })
        .catch(e => {
          console.log('e: ', e);
        })
      }
    },
    getCallRatio() {
      if (this.requestValue <= 0) return;
      this.updateCount++
      let uc = this.updateCount;
      this.payMpl = "....";
      const value = String(this.requestValue);
      // console.log("getCallRatio", value)
      manager.getCallRatio(value+"")
      .then(res => {
        // console.log(res)
        if (uc == this.updateCount) {
          this.payMpl = web3Utils.fromWei(res, 'ether');
        }
        // else {
          // console.log("lagacy data")
        // }
      })
      .catch(e => {
        console.log('e: ', e);
      })
    },
    requestStone() {
      if (this.requestValue <= 0 || this.requestValue < 300) return;
      if (this.payMpl != '....' && this.payMpl != '') {
        this.loading_converter = true;
        const value = String(this.requestValue);
        // console.log(web3Utils.toWei(value, 'ether'));
        manager.requestStone(value)
        .then(() => {
          this.loading_converter = false;
          this.modal_confirm = false;
          this.openFinal();
        })
        .catch(e => {
          setTimeout(() => {
            this.getAllowence();
            this.loading_converter = false;
          }, 1000);
          console.log('e: ', e);
        })
      }
    },

  }
}
</script>

<style>

</style>