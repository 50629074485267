export const toNumberFormat = (str, len, paddOption) => {
	if (typeof str === "undefined") {
		return "0";
	}
	if (typeof len === "undefined") {
		len = 2
	}
	var h = (str).toLocaleString('fullwide', {useGrouping:false}).split(".")
	h[0] = h[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	if (h.length == 2) {
		if (h[1].length > len) {
			h[1] = h[1].substr(0, len)
		}
		if (paddOption == "padding-zero") {
			var strs = h[1].split("")
			for ( var i = strs.length ; i < len ; i++ ) {
				strs.push("0");
			}
			h[1] = strs.join("")
		}
		str = h.join(".")
	} else {
		str = h[0]
	}
	return str
}