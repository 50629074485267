import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/Home.vue'
import ExchangeView from '../views/Exchange.vue'
import EventView from '../views/event/Event.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/exchange',
    name: 'exchange',
    component: ExchangeView
  },
  // {
  //   path: '/season',
  //   name: 'season',
  //   component: EventView
  // },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return {x: 0, y: 0}
  }
})

export default router
