<template>
  <v-app id="app">
    <info-bar />
    <div class="wrapper">
      <app-bar />
      <router-view />
      <app-footer />
    </div>
  </v-app>
</template>

<script>
import InfoBar from "@/components/common/InfoBar"
import AppBar from "@/components/common/AppBar"
import AppFooter from "@/components/common/AppFooter.vue";
export default {
  name: 'App',
  components: {
    InfoBar,
    AppBar,
    AppFooter
  },
  mounted() {
    this.$store.dispatch('checkUserInfo');
    this.$store.dispatch('checkChain');
  },
};
</script>
