import types from './types';
import { getCookie, setCookie, deleteCookie } from '@/utils/cookie';

const {
    SET_USER_INFO,
    REMOVE_USER_INFO,
    CHECK_USER_INFO,
    LOGIN,
    LOGOUT,
} = types;

const userModule = {
    state: {
        userInfo: {
            walletAddress: '',
            lastAccessTime: 0,
            token: '',
            userId: '',
        },
    },
    getters: {
        userInfo: state => state.userInfo,
    },
    mutations: {
        [SET_USER_INFO](state, userInfo) {
            state.userInfo = userInfo;
            setCookie('userInfo', JSON.stringify(userInfo), 2);
        },
        [REMOVE_USER_INFO](state) {
            state.userInfo = {
                lastAccessTime: 0,
                walletAddress: '',
                token: '',
            };
        },
    },
    actions: {
        [LOGOUT]({ commit }) {
            deleteCookie('userInfo');
            commit(REMOVE_USER_INFO);
        },
        [LOGIN]({ commit }, { userInfo, token }) {
            userInfo.token = token;
            setCookie('accessToken', token, 24);
            commit(SET_USER_INFO, userInfo);
        },
        [CHECK_USER_INFO]({ commit }) {
            const userInfo = getCookie('userInfo');
            if (userInfo) {
                commit(SET_USER_INFO, JSON.parse(userInfo));
            }
        },
    },
}

export default userModule;