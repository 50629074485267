export const setCookie = (cname, cvalue, exhours) => {
    const expires = `expires=${new Date(Date.now() + exhours * 60 * 60 * 1000).toUTCString()}`;
    document.cookie = `${cname}=${cvalue};${expires};path=/`;
}

export const getCookie = (cname) => {
    const name = cname + '=';
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');

    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];

        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }

        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }

    return '';
};

export const deleteCookie = (cname) => {
    document.cookie = `${cname}=;${new Date().toUTCString()};path=/`;
};