<template>
  <header class="header" :class="{ 'blackStyle': $route.name === 'exchange' }">
    <div class="header__inner">
      <div @click="goHome" class="header__inner--logo"></div>
      <nav class="header__inner--gnb">
        <v-btn href="https://medium.com/@pocketbattlesnftwar/pocket-championship-season-6-announcement-7a154246512e" target="_black" class="season-btn">SEASON 6</v-btn>
        <v-btn class="get-btn" target="_blank" :href="'https://www.meversedex.io/swap?f=USDC&t=POCKET'">Get MPL TOKEN</v-btn>
        <v-btn to="/exchange" class="EXCHANGE">EXCHANGE</v-btn>
        <v-btn href="/#news">NEWS</v-btn>
        <v-btn class="link-btn" target="_blank" :href="'https://meversedex.gitbook.io/pocketbattlesnftwar/'">DOCS</v-btn>
      </nav>
      <div @click="openMenu" class="menu-btn">
        <div class="menu-btn__icon">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </div>

    <div v-if="menu" class="mobile-menu">
      <div class="mobile-menu__inner">
        <div @click="openMenu" class="mobile-menu__inner--close"></div>
        <nav class="mobile-menu__inner--gnb">
          <v-btn @click="closeMenu" href="https://medium.com/@pocketbattlesnftwar/pocket-championship-season-6-announcement-7a154246512e" target="_black" class="season-btn">SEASON 6</v-btn>
          <v-btn class="get-btn" target="_blank" :href="'https://www.meversedex.io/swap?f=USDC&t=POCKET'">Get MPL TOKEN</v-btn>
          <v-btn @click="closeMenu" to="/exchange">EXCHANGE</v-btn>
          <v-btn @click="closeMenu" href="/#news">NEWS</v-btn>
          <v-btn class="link-btn" target="_blank" :href="'https://meversedex.gitbook.io/pocketbattlesnftwar/'">DOCS</v-btn>
        </nav>
        <div class="mobile-menu__inner--sns">
          <a target="_blank" href="https://www.facebook.com/PBNFTWar" class="facebook"></a>
          <a target="_blank" href="https://medium.com/@pocketbattlesnftwar" class="medium"></a>
          <a target="_blank" href="https://discord.com/invite/meverseofficialchannel" class="discord"></a>
          <a target="_blank" href="https://twitter.com/PBNFTWar" class="twitter"></a>
          <a target="_blank" href="https://meversedex.gitbook.io/pocketbattlesnftwar/" class="gitbook"></a>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: 'AppBarView',
  data() {
    return {
      menu: false,
    }
  },
  methods: {
    openMenu() {
      this.menu = !this.menu;
      if (this.menu) {
        document.querySelector('html').style.overflow = 'hidden';
      } else {
        document.querySelector('html').style.overflow = 'visible';
      }
    },
    closeMenu() {
      if (this.menu) {
        this.menu = false;
        document.querySelector('html').style.overflow = 'visible';
      }
    },
    goHome() {
      if (this.$route.name == 'home') return;
      this.$router.push('/');
    }
  }
}
</script>

<style>

</style>