<template>
  <div class="season">
    <div class="season__inner">
      <div class="content-header">
        <div class="tab-btn" @click="changeTab(index)" :class="{ 'isActive': tabState == index }" v-for="(item, index) in tabs" :key="index"><p v-html="item"></p></div>
      </div>

      <div v-for="(item, index) in viewList" :key="index">
        <component v-if="tabState === index" :is="item"></component>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EvnetView',
  data() {
    return {
      tabState: '',
      tabs: {
        season: 'Season 5',
        winnerRank: 'EXP <br />Ranking Event',
        // havahRank: 'Havah Event',
      },
      viewList: {
        season: () =>
          import("./Season5.vue"),
        winnerRank: () =>
          import("./WinnerRank.vue"),
        // havahRank: () =>
        //   import("./HavahRank.vue"),
      },
    }
  },
  mounted() {
    if (this.$route.query.t) {
      this.tabState = this.$route.query.t;
    } else {
      this.tabState = 'season'
    }
  },
  methods: {
    changeTab(item) {
      if (this.tabState == item) return;
      this.tabState = item;
      this.$router.push(`/season?t=${this.tabState}`);
    },
  }
}
</script>

<style>

</style>