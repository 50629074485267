import config from '@/config';
import store from '@/store';
import { request, callTransactionPromise, callTransaction } from '@/utils/promise.js';
import {
  web3,
  injectContract,
  injectContractWithType,
  injectContractWithTypeAndAddress,
} from '@/libs/injectionManager';

const Type = {
  TOKEN: 'token',
  CONVERTER: 'converter',
}
const MAX_SUPPLY = '0xffffffffffffffffffffffffffffffff';

const METHODS = {
  GET_BALANCE_OF: 'balanceOf',
  GET_ALLOWANCE: 'allowance',
  APPROVE: 'approve',
  REQUESTSTONE: 'requestStone',
  GETCALLRATIO: 'getInputCallRatio'
}

const MEVERSE_GAS = '300000';
const MEVERSE_GAS_PRICE = '333333333334';

const getSendData = (toSendWei) => {
  const sendData = {};
  sendData.gas = MEVERSE_GAS;
  sendData.gasPrice = MEVERSE_GAS_PRICE;
  sendData.value = toSendWei;
  sendData.from = store.state.user.userInfo.walletAddress;
  return sendData;
};
const MEVERSE_NUMBER = 0;
const TOKENS_ADDRESS = config.contract[MEVERSE_NUMBER].token;
const CONVERTER_ADDRESS = config.contract[MEVERSE_NUMBER].converter.address;

class web3Manager {
  getBalanceOf = (token, userAddress) => {
    return new Promise((resolve, reject) => {
      const tokenAddress = TOKENS_ADDRESS[token];
      const tokenInstance = injectContractWithTypeAndAddress(Type.TOKEN, tokenAddress);
      tokenInstance.methods[METHODS.GET_BALANCE_OF](userAddress)
      .call()
      .then(res => {
        resolve(res);
      })
      .catch(e => {
        reject(e);
      })
    });
  }

  getAllowance = (token, userAddress, router) => {
    return new Promise((resolve, reject) => {
      const owner = userAddress;
      const spender = router;
      const tokenAddress = TOKENS_ADDRESS[token];
      const tokenInstance = injectContractWithTypeAndAddress(Type.TOKEN, tokenAddress);
      tokenInstance.methods[METHODS.GET_ALLOWANCE](owner, spender)
      .call()
      .then(res => {
        resolve(res);
      })
      .catch(e => {
        reject(e);
      })
    });
  }

  approve = (token, spender) => {
    return new Promise((resolve, reject) => {
      const tokenAddress = TOKENS_ADDRESS[token];
      const toSendWei = '0x00';
      const sendData = getSendData(toSendWei);
      const tokenInstance = injectContractWithTypeAndAddress(Type.TOKEN, tokenAddress);
      tokenInstance.methods[METHODS.APPROVE](spender, MAX_SUPPLY)
      .send(sendData)
      .then(res => {
        resolve(res);
      })
      .catch(e => {
        reject(e);
      });
    })
  }

  getCallRatio = (amt) => {
    return new Promise((resolve, reject) => {
      const instance = injectContractWithType("converter");
      instance.methods[METHODS.GETCALLRATIO](amt)
      .call()
      .then(res => {
        resolve(res);
      })
      .catch(e => {
        reject(e);
      })
    });
  }

  requestStone = (amt) => {
    return new Promise((resolve, reject) => {
      const toSendWei = '0x00';
      const sendData = getSendData(toSendWei);
      const instance = injectContractWithType("converter");
      instance.methods[METHODS.REQUESTSTONE](amt)
      .send(sendData)
      .then(res => {
        resolve(res);
      })
      .catch(e => {
        reject(e);
      });
    });
  }

}

export const manager = new web3Manager();