import store from '@/store';
import axios from '@/utils/axios';
import web3Utils from 'web3-utils';
import { getCookie } from '@/utils/cookie';
import define from '@/define';
const url = define.URL;

const accountHandler = (accounts) => {
  if (accounts.length > 0) {
    store.dispatch('logout');
  } else {
    loginCheck(accounts[0]);
  }
}
const checkAccountHandler = (accounts) => {
  if (accounts.length > 0) {
    loginCheck(accounts[0]);
  } else {
    store.dispatch('logout');
  }
}

const chainHandler = (id) => {
  store.dispatch('changeChainId', { chainId: id, type: 'metamask' });
}

const getChain = () => {
  window.ethereum.request({ method: 'eth_chainId' })
    .then(res => {
      store.dispatch('changeChainId', { chainId: res, type: 'metamask' });
    }
  );
}

export const connectToMetamask = () => {
  if (window.ethereum) {
    let isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    if (isMobile) {
      window.ethereum.request({ method: 'eth_requestAccounts' }).then(() => {
        const address = window?.ethereum?.selectedAddress;
        loginCheck(address);
      })
      .catch(e => {
        console.log('mobile error', e);
      })
    } else {
      window.ethereum
        .request({ method: 'wallet_requestPermissions',
        params: [{
          eth_accounts: {}
        }]
      }).then(() => {
        const address = window?.ethereum?.selectedAddress;
        loginCheck(address);
      })
      .catch(e => {
        console.log('error: ', e)
      })
    }
  } else {
    let isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    if (isMobile) {
      window.location.href = 'https://metamask.app.link/dapp/pocketbattlesnftwar.com';
      return;
    }

    window.open('https://metamask.io/download', 'metamask');
  }
};

// export const connectToMetamaskPromise = () => {
//   return new Promise((resolve, reject) => {
//     if (window.ethereum) {

//       window.ethereum
//         .request({ method: 'wallet_requestPermissions',
//         params: [{
//           eth_accounts: {}
//         }]
//       }).then(() => {
//         const address = window?.ethereum?.selectedAddress;
//         resolve(true);
//         loginCheck(address);
//       })
//       .catch(e => {
//         reject(e);
//       })
//     } else {
//       window.open('https://metamask.io/download', 'metamask');
//       reject();
//     }
//   })
// };

if (window.ethereum) {
  window.ethereum.on('accountsChanged', accountHandler);
  window.ethereum.on('chainChanged', chainHandler);
  // window.ethereum.request({ method: 'eth_requestAccounts' }).then(accs => checkAccountHandler(accs));
}
let prevAddress;
let interval;
const sign = (message, address) => {
  return new Promise((resolve, reject) => {
    const params = [message, address];
    const method = 'personal_sign';
    try {
      window.ethereum.sendAsync({ method, params }, (err, result) => {
        resolve(result);
      });
    } catch(e) {
      reject(e);
    }
  })
}
const login = async (address) => {
  const sign_message = web3Utils.keccak256(address);
  const result = await sign(sign_message, address);
  const token = getCookie('accessToken');
  axios.post(`${url}/users/v1/login`, {
    token,
    walletAddress: address,
    message: sign_message,
    signature: result?.result
  })
  .then(res => {
    const token = res.data.token;
    const userId = res.data.userId;
    const lastAccessTime = Date.now();
    store.dispatch('login', { token, userInfo: { userId, walletAddress: address, lastAccessTime }});
  })
  .catch(e => {
    //@TODO 로그인 실패시 어떻게 처리할 것인가?
    console.log('e: ', e);
  });
}

const loginCheck = (address) => {
  const prev = store.getters.userInfo.walletAddress;

  if (address === prev) {
    return;
  }

  if (prevAddress === address) {
    return;
  }

  if (interval) {
    clearTimeout(interval);
    interval = null;
    prevAddress = '';
    return;
  }

  interval = setTimeout(() => {
    login(address);
    prevAddress = '';
    interval = null;
  }, 50);

  prevAddress = address;
  getChain();
}